<template>
  <div class="relative rounded-sm bg-red-50 p-4 top-2">
    <div class="absolute -top-3 -right-3">
      <XCircleIcon class="h-6 w-6 text-gray-400 cursor-pointer" v-on:click="$emit('close')" aria-hidden="true" />
    </div>
    <div class="flex">
      <div class="flex-shrink-0">
        <ExclamationCircleIcon class="h-6 w-6 text-red-400" hidden="true" />
      </div>
      <div class="ml-3">
        <h3 class="text-sm text-left font-medium text-red-800">
          {{ error.message }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationCircleIcon, XCircleIcon } from "@heroicons/vue/solid";

export default {
  props: ["error"],
  components: {
    XCircleIcon,
    ExclamationCircleIcon
  },
};
</script>

<template>
  <!-- Change password POPUP -->
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="cancel">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="changepassword"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >

          <TransitionChild
            as="profile"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                p-6
                my-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-2xl
                border border-gray-300
              "
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900 text-center"
              >
                Change Password
              </DialogTitle>
              <div>
                <div v-if="error" class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                  <ErrorAlert v-on:close="closeError" v-bind:error="error"></ErrorAlert>
                </div>
                <div v-if="info" class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                  <InfoAlert v-on:close="closeInfo" v-bind:info="info"></InfoAlert>
                </div>
                <div class="my-3">
                  <div class="w-1/3 inline-block">Old Password:</div>
                  <div class="w-1/2 inline-block">
                    <input
                      type="password"
                      :size="18"
                      v-model="oldpassword"
                      class="
                      block
                      w-full
                      shadow-sm
                      text-md
                      focus:ring-indigo-500
                      focus:border-indigo-500
                      border-gray-300
                      -ml-3
                      rounded-md
                      "
                    />
                  </div>
                </div>
                <div class="my-3">
                  <div class="w-1/3 inline-block">New Password:</div>
                  <div class="w-1/2 inline-block">
                    <input
                      type="password"
                      :size="18"
                      v-model="newpassword"
                      class="
                      block
                      w-full
                      shadow-sm
                      text-md
                      focus:ring-indigo-500
                      focus:border-indigo-500
                      border-gray-300
                      -ml-3
                      rounded-md
                      "
                    />
                  </div>
                </div>
              </div>

              <div class="mt-4">
                <span class="space-x-2">
                  <button
                    type="button"
                    class="
                      inline-flex
                      justify-center
                      px-4
                      py-2
                      text-sm
                      font-medium
                      text-purple-600
                      bg-purple-100
                      border border-transparent
                      rounded-md
                      hover:bg-purple-200
                      focus:outline-none
                      focus-visible:ring-2
                      focus-visible:ring-offset-2
                      focus-visible:ring-blue-500
                    "
                    @click="savePassword"
                  >
                    Save New Password
                  </button>
                  <button
                    type="button"
                    class="
                      inline-flex
                      justify-center
                      px-4
                      py-2
                      text-sm
                      font-medium
                      text-red-400
                      border border-transparent
                      rounded-md
                      hover:text-red-600
                      focus:outline-none
                      focus-visible:ring-2
                      focus-visible:ring-offset-2
                      focus-visible:ring-blue-500
                    "
                    @click="cancel"
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import { resetPassword } from "@/services/user.js";
import ErrorAlert from "@/components/alerts/ErrorAlert.vue";
import InfoAlert from "@/components/alerts/InfoAlert.vue";


export default {
  name: "ChangePassword",
  props: ["isOpen"],
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    ErrorAlert,
    InfoAlert,
    TransitionChild,
    TransitionRoot,
  },
  data() {
    return {
      oldpassword: "",
      newpassword: "",
      info: null,
      error: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
      this.info = null;
      this.error = null;
      this.oldpassword = "";
      this.newpassword = "";
    },
    closeInfo() {
      this.info = null;
    },
    closeError() {
      this.error = null;
    },
    cancel() {
      this.oldpassword = "";
      this.newpassword = "";
      this.error = null;
      this.info = null;
      this.closeModal();
    },
    savePassword() {
      console.log("changing password");
      resetPassword(this.oldpassword, this.newpassword).then(() => {
        this.error = null;
        this.info = {message: "Password Changed"};
        setTimeout(this.closeModal, 1000);
      })
      .catch( (e) => {
        console.log("change password error: " + e);
        e.message = "Error Changing Password";
        this.info = null;
        this.error = e;
      });
    },
  },
};
</script>


import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

// firebase init - add your own config here
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

let firebaseConfig = {
  apiKey: "AIzaSyAVGjaHFHRE0ANA_UrZiet1kog7Ej0M4Rc",
  authDomain: "patensys-e68b2.firebaseapp.com",
  projectId: "patensys-e68b2",
  storageBucket: "patensys-e68b2.appspot.com",
  messagingSenderId: "14976022517",
  appId: "1:14976022517:web:ccf9936aa6f057173b52bc",
  measurementId: "G-J6C8QYV5F7",
};

if (location.hostname == "dev.patensys.com") {
  firebaseConfig = {
    apiKey: "AIzaSyBY3TcYbTdfPMj1_a_acFWEO9eyOSLXwAI",
    authDomain: "patensys-dev.firebaseapp.com",
    projectId: "patensys-dev",
    storageBucket: "patensys-dev.appspot.com",
    messagingSenderId: "383963345176",
    appId: "1:383963345176:web:68dae1e374d959c441ae6d"
  };
}
if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
  firebaseConfig = {
    apiKey: "AIzaSyBY3TcYbTdfPMj1_a_acFWEO9eyOSLXwAI",
    authDomain: "patensys-dev.firebaseapp.com",
    projectId: "patensys-dev",
    storageBucket: "patensys-dev.appspot.com",
    messagingSenderId: "383963345176",
    appId: "1:383963345176:web:68dae1e374d959c441ae6d"
  };
}
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const use_emulator = false;

if (location.hostname === "localhost" && use_emulator) {
  auth.useEmulator("http://localhost:9099");
  db.useEmulator("localhost", 8081);
  storage.useEmulator("localhost", 9198);
}
await db.enablePersistence({synchronizeTabs: true});
const Timestamp = firebase.firestore.Timestamp;
const Increment = firebase.firestore.FieldValue.increment;
const authName = firebase.auth;
const STATE_CHANGED = firebase.storage.TaskEvent.STATE_CHANGED;

function generateId() {
  console.log("eliminate this function");
  return db.collection("users").doc().id;
}
// export utils/refs
export {
  db,
  auth,
  storage,
  STATE_CHANGED,
  Timestamp,
  authName,
  generateId,
  Increment,
};

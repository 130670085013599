<template>
  <!-- PROFILE POPUP -->
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="cancel">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="profile"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >

          <TransitionChild
            as="profile"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                p-6
                my-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-2xl
                border border-gray-300
              "
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900 text-center"
              >
                Your Profile
              </DialogTitle>
              <div>
              <div class="my-3">
                <div class="w-1/3 inline-block">First Name:</div>
                <div class="w-1/2 inline-block">
                  <input
                    type="text"
                    :size="18"
                    v-model="updatedUser.firstName"
                    class="
                    block
                    w-full
                    shadow-sm
                    text-md
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    border-gray-300
                    -ml-3
                    rounded-md
                    "
                  />
                </div>
              </div>
              <div class="my-3">
                <div class="w-1/3 inline-block">Last Name:</div>
                <div class="w-1/2 inline-block">
                  <input
                    type="text"
                    :size="18"
                    v-model="updatedUser.lastName"
                    class="
                    block
                    w-full
                    shadow-sm
                    text-md
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    border-gray-300
                    -ml-3
                    rounded-md
                    "
                  />
                </div>
              </div>
              <div class="my-3">
                <div class="w-1/3 inline-block">Phone Number:</div>
                <div class="w-1/2 inline-block">
                  <input
                    type="text"
                    :size="18"
                    v-model="updatedUser.phoneNumber"
                    class="
                    block
                    w-full
                    shadow-sm
                    text-md
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    border-gray-300
                    -ml-3
                    rounded-md
                    "
                  />
                </div>
              </div>
              <div class="my-3">
                <div class="w-1/3 inline-block">Email:</div>
                <div class="w-1/2 inline-block">
                  <div>
                    {{ user.email }}
                  </div>
                </div>
              </div>

              </div>

              <div class="mt-4">
                <span class="space-x-2">
                  <button
                    type="button"
                    class="
                      inline-flex
                      justify-center
                      px-4
                      py-2
                      text-sm
                      font-medium
                      text-purple-600
                      bg-purple-100
                      border border-transparent
                      rounded-md
                      hover:bg-purple-200
                      focus:outline-none
                      focus-visible:ring-2
                      focus-visible:ring-offset-2
                      focus-visible:ring-blue-500
                    "
                    @click="save"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    class="
                      inline-flex
                      justify-center
                      px-4
                      py-2
                      text-sm
                      font-medium
                      text-red-400
                      border border-transparent
                      rounded-md
                      hover:text-red-600
                      focus:outline-none
                      focus-visible:ring-2
                      focus-visible:ring-offset-2
                      focus-visible:ring-blue-500
                    "
                    @click="cancel"
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import { updateUser } from "@/services/user.js";

export default {
  name: "ProfileView",
  props: ["isOpen", "user"],
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  data() {
    return {
      updatedUser: {},
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    cancel() {
      this.updatedUser.firstName = this.user.firstName;
      this.updatedUser.lastName = this.user.lastName;
      this.updatedUser.phoneNumber = this.user.phoneNumber;
      this.closeModal();
    },
    save() {
      console.log("saving" + this.updatedUser.lastName);
      updateUser(this.user.uid, this.updatedUser).then((savedUser) => {
        this.$emit("saved", {data: savedUser});
      });
      this.closeModal();
    },
  },
  mounted() {
    this.updatedUser.firstName = this.user.firstName;
    this.updatedUser.lastName = this.user.lastName;
    this.updatedUser.phoneNumber = this.user.phoneNumber;
  },
  setup() {
    return {
    };
  },
};
</script>
